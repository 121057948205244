import { Collapse, Row, Col, Typography } from "antd";
import { memo } from "react";
import { useQueryParam } from "use-query-params";
import htmlDecode from "../helper/htmlDecode";
import { DataRaceType } from "../helper/myTypes";
import { ParamRace, ParamRaceType } from "../helper/queryParamNames";
import { getDay, getTime } from "../helper/timestampToString";

import RaceWrapper from "../Race/RaceWrapper";

type RaceHeaderType = {
  race: DataRaceType;
};

export const RaceHeader = memo(function RaceHeader({ race }: RaceHeaderType) {
  return (
    <Row wrap={false} gutter={10}>
      <Col style={{ whiteSpace: "nowrap" }}>
        <Typography.Title level={4}>{race.race_no}</Typography.Title>
      </Col>
      <Col style={{ width: "100%" }}>
        <Row justify="space-between">
          <Typography.Text>{htmlDecode(race.race_class)}</Typography.Text>
          <Typography.Text>{getDay(race.timeJs)}</Typography.Text>
        </Row>
        <Row justify="space-between">
          <Typography.Text>{htmlDecode(race.race_run)}</Typography.Text>
          <Typography.Text strong>{getTime(race.timeJs)}</Typography.Text>
        </Row>
      </Col>
    </Row>
  );
});

type RacelistType = {
  races: DataRaceType[];
  allRaces: DataRaceType[];
  getRace: (arg1: number) => void;
  flags: boolean;
  distanceSort: number;
};

const Racelist = memo(function Racelist({
  races,
  allRaces,
  getRace,
  flags,
  distanceSort,
}: RacelistType) {
  const [rennen, setRace] = useQueryParam(ParamRace, ParamRaceType);
  return (
    <Collapse
      expandIcon={() => null}
      accordion
      onChange={(value) => {
        if (!value) {
          setRace(null);
          return;
        }
        setRace(parseInt(value.toString(), 10));
      }}
      activeKey={rennen ? rennen : undefined}
      destroyInactivePanel
    >
      {races.map((r) => {
        return (
          <Collapse.Panel key={r.id} header={<RaceHeader race={r} />}>
            <RaceWrapper
              flags={flags}
              distanceSort={distanceSort}
              getRace={getRace}
              race={r}
              races={allRaces}
            />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
});

export default Racelist;
