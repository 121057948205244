import baseurl, { debugParser } from "./baseurl";
import handleErrors from "./fetchHelper";
import { DataCompetitionType } from "./myTypes";
import offlineWarning, { removeWarning } from "./offlineWarning";

const parseCompetitionList = async () => {
  const url = encodeURI(baseurl + "/API_IMAS_wettkampf_liste.php");
  try {
    const data = await fetch(url).then(handleErrors);
    const content = await data.text();
    const wkList: DataCompetitionType[] = JSON.parse(content);
    if (!wkList) {
      return [];
    }
    removeWarning();
    return wkList;
  } catch (e) {
    if (debugParser) {
      console.warn(e, url);
    }
    offlineWarning();
    return null;
  }
};

export default parseCompetitionList;
