import { message } from "antd";

const offlineWarning = () => {
  const conf = {
    key: 99,
    content: "OFFLINE",
    duration: 10,
    maxCount: 1,
  };
  message.warning(conf);
};

export const removeWarning = () => {
  message.destroy(99);
  return true;
};

export default offlineWarning;
