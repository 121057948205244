import { Typography, Button, Space } from "antd";
import { memo } from "react";
import { useQueryParam } from "use-query-params";
import htmlDecode from "../helper/htmlDecode";
import { DataRaceType } from "../helper/myTypes";
import { ParamRace, ParamRaceType } from "../helper/queryParamNames";
import { getDaywithoutDate, getTime } from "../helper/timestampToString";

type OtherRaceLineType = {
  race: DataRaceType;
  bold: boolean;
};

const OtherRaceLine = memo(function OtherRaceLine({
  race,
  bold,
}: OtherRaceLineType) {
  const [, setRace] = useQueryParam(ParamRace, ParamRaceType);
  return (
    <Button
      size="small"
      type="default"
      onClick={() => {
        setRace(race.id);
      }}
      style={{ textAlign: "start" }}
    >
      <Typography.Text strong={bold}>
        {htmlDecode(race.race_run)}
        {" - "}
        {getDaywithoutDate(race.timeJs)} {getTime(race.timeJs)}
      </Typography.Text>
    </Button>
  );
});

type OtherRacesType = {
  race: DataRaceType;
  races: DataRaceType[];
};

const OtherRaces = memo(function OtherRaces({ race, races }: OtherRacesType) {
  const myRaces = races.filter((r) => r.race_class === race.race_class);
  if (!myRaces || myRaces.length <= 1) {
    return null;
  }
  return (
    <div style={{ paddingTop: "5px" }}>
      <Space
        style={{ rowGap: "5px", columnGap: "5px" }}
        size="small"
        wrap={true}
      >
        {myRaces.map((r) => {
          if (r.id === race.id) {
            return <OtherRaceLine key={r.id} bold={true} race={r} />;
          }
          return <OtherRaceLine key={r.id} bold={false} race={r} />;
        })}
      </Space>
    </div>
  );
});

export default OtherRaces;
