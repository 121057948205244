import { Typography, Row, List, Col } from "antd";
import { memo } from "react";
import { useQueryParam } from "use-query-params";
import htmlDecode from "../helper/htmlDecode";
import { DataCompetitionType } from "../helper/myTypes";
import {
  ParamWkID,
  ParamWkIDType,
  ParamWkOrg,
  ParamWkOrgType,
} from "../helper/queryParamNames";
import SportColorTable from "../helper/SportsColorTable";

type CompetitionItemType = {
  comp: DataCompetitionType;
};

const CompetitionItem = memo(function CompetitionItem({
  comp,
}: CompetitionItemType) {
  const [, setWkID] = useQueryParam(ParamWkID, ParamWkIDType);
  const [, setWkOrg] = useQueryParam(ParamWkOrg, ParamWkOrgType);
  return (
    <List.Item
      className="CompListItem"
      onClick={() => {
        setWkID(comp.competition);
        setWkOrg(comp.pfad.replace("/", ""));
      }}
    >
      <div
        className="CompListItemDiv"
        style={{
          backgroundImage: `linear-gradient(to right, ${
            SportColorTable[comp.sports]
          },transparent)`,
          backgroundSize: "6% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Row>
          <Col span={1}>
            <div
              style={{
                display: "flex",
                placeContent: "center",
                placeItems: "center",
                height: "100%",
                width: "100%",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              <div
                style={{
                  //textOrientation: "upright",
                  //writingMode: "vertical-rl",
                  // rotate: "90deg",
                  rotate: "-90deg",
                }}
              >
                {comp.sports}
              </div>
            </div>
          </Col>
          <Col xs={23} md={18}>
            <Row style={{ width: "100%" }}>
              <Typography.Title level={3}>
                {htmlDecode(comp.name)}
              </Typography.Title>
            </Row>
            <Row gutter={10}>
              <Col>
                <Typography.Text strong>{comp.from}</Typography.Text>
              </Col>
              <Col>
                <Typography.Text strong>{" - "}</Typography.Text>
              </Col>
              <Col>
                <Typography.Text strong>{comp.to}</Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={5} style={{ textAlign: "right" }}>
            <Row
              style={{
                textAlign: "right",
                fontSize: "18px",
                minHeight: "18px",
              }}
            >
              <div style={{ width: "100%" }}>{htmlDecode(comp.region)}</div>
            </Row>
            <Row
              style={{ textAlign: "right", fontSize: "20px", fontWeight: 500 }}
            >
              <div style={{ width: "100%" }}>{comp.regattastrecke}</div>
            </Row>
          </Col>
        </Row>
      </div>
    </List.Item>
  );
});

export default CompetitionItem;
