import moment from "moment";

export const timestampToMoment = (ts: number): moment.Moment => {
  return moment.unix(ts).utc();
};

export const timestampToStringLong = (ts: number): string => {
  const m = timestampToMoment(ts);
  if (m.isValid()) {
    return m.format("dddd, DD.MM.YYYY");
  }
  return "";
};

export const timestampToStringShort = (ts: number): string => {
  const m = timestampToMoment(ts);
  if (m.isValid()) {
    return m.format("DD.MM.YYYY");
  }
  return "";
};

export const getDay = (timeJs: moment.Moment | null): string => {
  if (!timeJs || !timeJs.isValid()) {
    return "";
  }
  return timeJs.format("dd, DD.MM");
};

export const getDaywithoutDate = (timeJs: moment.Moment | null): string => {
  if (!timeJs || !timeJs.isValid()) {
    return "";
  }
  return timeJs.format("dd");
};

export const getTime = (timeJs: moment.Moment | null): string => {
  if (!timeJs || !timeJs.isValid()) {
    return "";
  }
  return timeJs.format("HH:mm");
};
