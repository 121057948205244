import { Row, Input } from "antd";

type WettkampfSearchbarType = {
  searchValue: string;
  setSearchValue: (arg1: string) => void;
};

const WettkampfSearchbar = ({
  searchValue,
  setSearchValue,
}: WettkampfSearchbarType) => {
  return (
    <Row style={{ marginBottom: "5px" }}>
      <Input.Search
        placeholder="search"
        onSearch={() => {}}
        enterButton
        allowClear
        value={searchValue}
        onChange={({ target: { value } }) => {
          setSearchValue(value);
        }}
      />
    </Row>
  );
};

export default WettkampfSearchbar;
