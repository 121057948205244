import React from "react";
import { NavigateFunction } from "react-router-dom";

import parseRace from "./helper/parseRace";
import parseRaceList from "./helper/parseRaceList";
import RacelistWrapper from "./Racelist/RacelistWrapper";
import WettkampfFooter from "./Wettkampf/WettkampfFooter";
import WettkampfHeader from "./Wettkampf/WettkampfHeader";
import WettkampfSearchbar from "./Competitions/WettkampfSearchbar";
import WettkampfTitle from "./Wettkampf/WettkampfTitle";
import parseRacesAll from "./helper/parseRaceAll";
import BackToTop from "./Racelist/BackToTop";
import WettkampfLinks from "./Wettkampf/WettkampfLinks";
import {
  DataCompetitionType,
  DataRaceQueryType,
  DataRaceType,
} from "./helper/myTypes";
import { Col, Row, Spin } from "antd";
import WettkampfCss from "./Wettkampf/WettkampfCss";
import RaceFilters from "./Wettkampf/RaceFilters";

type WettkampfType = {
  wkID: string;
  wkOrg: string;
  nav: NavigateFunction;
};

type WettkampfStateType = {
  competitionInfos: DataCompetitionType | null;
  competitionRaces: DataRaceQueryType;
  searchValue: string;
  genderFilters: string[];
  distanceFilters: string[];
  typeFilters: string[];
};

export default class Wettkampf extends React.Component<
  WettkampfType,
  WettkampfStateType
> {
  constructor(props: WettkampfType) {
    super(props);
    const { wkID, wkOrg } = props;
    const storageKey = wkOrg + "-" + wkID + "-";
    const infos = localStorage.getItem(storageKey + "infos");
    const races = localStorage.getItem(storageKey + "races");
    this.state = {
      competitionInfos: infos ? JSON.parse(infos) : null,
      competitionRaces: races ? JSON.parse(races) : null,
      searchValue: "",
      genderFilters: [],
      distanceFilters: [],
      typeFilters: [],
    };
  }

  private refreshTimer = null;

  setSearchValue = (searchValue: string) => {
    this.setState({ searchValue });
  };

  componentDidMount = () => {
    this.getDataRaceList();
  };

  componentDidUpdate = () => {
    const { wkID, wkOrg } = this.props;
    const { competitionInfos, competitionRaces } = this.state;
    const storageKey = wkOrg + "-" + wkID + "-";
    localStorage.setItem(
      storageKey + "infos",
      JSON.stringify(competitionInfos)
    );
    localStorage.setItem(
      storageKey + "races",
      JSON.stringify(competitionRaces)
    );
  };

  componentWillUnmount = () => {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  };

  getDataRaceList = async () => {
    const { wkID, wkOrg, nav } = this.props;
    if (!wkID || !wkOrg) {
      console.warn("Missing Paramerters 1");
      nav("/", { replace: true });
      return;
    }
    const wkInfo = await parseRaceList(wkID, wkOrg);
    if (wkInfo) {
      this.setState({ competitionInfos: wkInfo });
    }
    this.getDataAllRaces();
  };

  getDataAllRaces = async () => {
    const { wkID, wkOrg, nav } = this.props;

    if (!wkID || !wkOrg) {
      console.warn("Missing Paramerters 1");
      nav("/", { replace: true });
      return;
    }

    const raceData = await parseRacesAll(wkID, wkOrg);
    if (raceData) {
      this.setState({ competitionRaces: raceData });
    }
  };

  getDataRace = async (rID: number) => {
    const { wkID, wkOrg } = this.props;
    // console.log("GetRace", rID);
    const raceData: DataRaceType | null = await parseRace(wkID, wkOrg, rID);
    if (raceData) {
      const competitionRaces: DataRaceQueryType = this.state.competitionRaces;
      /*
      if (competitionRaces && competitionRaces[rID]) {
        console.log("EARLY RETURN")
        return;
      }
      */
      competitionRaces[rID] = raceData;
      // console.log("Refresh RaceData");
      this.setState({ competitionRaces });
    }
  };

  setGenderFilter = (values: string[]) => {
    this.setState({ genderFilters: values });
  };

  setDistanceFilter = (values: string[]) => {
    this.setState({ distanceFilters: values });
  };

  setTypeFilter = (values: string[]) => {
    this.setState({ typeFilters: values });
  };

  render = () => {
    const {
      competitionInfos,
      competitionRaces,
      searchValue,
      genderFilters,
      distanceFilters,
      typeFilters,
    } = this.state;
    if (!competitionInfos) {
      return (
        <div style={{ position: "absolute", left: "50%", top: "50%" }}>
          <Spin size="large" />
        </div>
      );
    }
    const { zeige_flaggen: flags, distanz_darstellung: distanceSort } =
      competitionInfos;
    return (
      <div className="wettkampfStyle">
        <WettkampfCss />
        <WettkampfHeader />
        <div className="wettkampfBody">
          <WettkampfTitle competition={competitionInfos} />
          <WettkampfLinks competition={competitionInfos} />
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <RaceFilters
                races={competitionRaces}
                genderFilters={genderFilters}
                setGenderFilter={this.setGenderFilter}
                distanceFilters={distanceFilters}
                setDistanceFilter={this.setDistanceFilter}
                typeFilters={typeFilters}
                setTypeFilter={this.setTypeFilter}
              />
            </Col>
            <Col span={24}>
              <WettkampfSearchbar
                searchValue={searchValue}
                setSearchValue={this.setSearchValue}
              />
            </Col>
          </Row>
          <RacelistWrapper
            flags={flags}
            distanceSort={distanceSort}
            getRace={this.getDataRace}
            races={competitionRaces}
            searchValue={searchValue}
            genderFilter={genderFilters}
            distanceFilter={distanceFilters}
            typeFilter={typeFilters}
          />
        </div>
        <BackToTop />
        <WettkampfFooter />
      </div>
    );
  };
}
