const htmlDecode = (input: string): string => {
  /*
  const doc = new DOMParser().parseFromString(input, "text/html");
  const txt = doc.documentElement.textContent;
  if (!txt) {
    return "";
  }
  return txt;
*/
  return input.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
};

export default htmlDecode;
