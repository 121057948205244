import { Row, Col, Typography } from "antd";
import { Tr, Td } from "react-super-responsive-table";
import moment from "moment";
import Flag from "./Flag";
import htmlDecode from "../helper/htmlDecode";
import { DataBoatType, DataCleanedBoatType } from "../helper/myTypes";
import { getDisableFlagValue } from "../PageHead/DisableFlagsView";
import { memo } from "react";

export const cleanTeamname = (value: string): string => {
  let myValue = value.replace("<b>", "");
  myValue = myValue.replace("</b>", "");
  myValue = myValue.trim();
  myValue = htmlDecode(myValue);
  return myValue;
};

export const cleanAthlets = (value: string): string[] => {
  let myValue = value.replace("<TABLE class=&#39;bootsbesatzung&#39;>", "");
  myValue = myValue.replace("</TABLE>", "");
  const values = myValue.split("</TD>");
  const names = [];
  for (let i = 0; i < values.length; i += 1) {
    let v = values[i];
    v = v.replace("<TR>", "");
    v = v.replace("<TD>", "");
    v = v.replace("</TD>", "");
    v = v.replace("</TR>", "");
    v = v.trim();
    v = htmlDecode(v);
    if (v !== "") {
      names.push(v);
    }
  }
  return names;
};

export const cleanedBoatData = (value: string): DataCleanedBoatType => {
  const split = value.split("<br/>");
  const team = cleanTeamname(split[0]);
  let athletes: string[] = [];
  if (split.length === 2) {
    const athletesStr = split[1];
    athletes = cleanAthlets(athletesStr);
  }

  return { team, athletes };
};

type DisplayTeamType = {
  team: string;
  athlets: string;
};

const DisplayTeam = ({ team, athlets }: DisplayTeamType) => {
  const names = cleanAthlets(athlets);
  return (
    <div>
      <Row>
        <Typography.Text strong>{team}</Typography.Text>
      </Row>
      <Row gutter={10}>
        {names.map((n, i) => {
          return (
            <Col key={i}>
              <Typography.Text>{n}</Typography.Text>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

type BoatType = {
  boat: DataBoatType;
  distances: string[];
  flags: boolean;
};

type FinalTimeType = {
  boat: DataBoatType;
};

const FinalTime = memo(function FinalTime({ boat }: FinalTimeType) {
  const times = boat[1];
  if (times) {
    const finalTime = times[0];
    if (finalTime) {
      const { timeOffset } = boat;
      if (timeOffset) {
        const to = moment(timeOffset);
        if (to.isValid()) {
          let toString = to.format("mm:ss.SSS");
          toString = toString.replace(/^0(?:0:0?)?/, "");
          toString = toString.replace(/0*$/, "");
          return (
            <>
              <Td className="tableBoatFinalTime">
                <Row>
                  <div className="BoatTimeValue">{htmlDecode(finalTime)}</div>
                  <div className="BoatTimeOffset">{" +" + toString}</div>
                </Row>
              </Td>
            </>
          );
        }
      }
      return (
        <>
          <Td className="tableBoatFinalTime">
            <Row>
              <div className="BoatTimeValue">{htmlDecode(finalTime)}</div>
            </Row>
          </Td>
        </>
      );
    }
  }

  return (
    <>
      <Td className="tableBoatFinalTime">-</Td>
      <Td className="tableBoatOffsetTime" />
    </>
  );
});

const Boat = memo(function Boat({ boat, distances, flags }: BoatType) {
  let athlets = "";
  const disableFlags = getDisableFlagValue();
  const split = boat[0].split("<br/>");
  const team = cleanTeamname(split[0]);
  if (split.length === 2) {
    athlets = split[1];
  }
  return (
    <Tr>
      <Td className="tableBoatRank">{boat.rank ? boat.rank : "-"}</Td>
      <Td className="tableBoatLane">{boat.bahn}</Td>
      {flags && !disableFlags ? (
        <Td className="tableBoatFlag">
          <Flag id={boat.id_flagge} />
        </Td>
      ) : null}
      <Td className="tableBoatTeam">
        <DisplayTeam team={team} athlets={athlets} />
      </Td>
      {distances.map((d: string) => {
        if (d === "0") {
          return null;
        }
        const di = parseInt(d);
        const v = boat[1];
        if (v) {
          const w = v[di];
          if (w) {
            return (
              <Td className="tableBoatSplitTime" key={d}>
                {w}
              </Td>
            );
          }
        }
        return (
          <Td className="tableBoatSplitTime" key={d}>
            {"-"}
          </Td>
        );
      })}
      <FinalTime boat={boat} />
    </Tr>
  );
});

export default Boat;
