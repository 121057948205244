import { Row, Typography } from "antd";
import htmlDecode from "../helper/htmlDecode";
import { DataCompetitionType } from "../helper/myTypes";

type DateDisplayType = {
  value: string;
};
const DateDisplay = ({ value }: DateDisplayType) => {
  return <Typography.Text strong>{value}</Typography.Text>;
};

type TitleDisplayType = {
  value: string;
};
const TitleDisplay = ({ value }: TitleDisplayType) => {
  return <Typography.Title level={3}>{value}</Typography.Title>;
};

type WettkampfTitleType = {
  competition: DataCompetitionType;
};
const WettkampfTitle = ({ competition }: WettkampfTitleType) => {
  const { name, from, to } = competition;
  return (
    <div className="wettkampfHeaderStyle">
      <Row>
        <TitleDisplay value={htmlDecode(name)} />
      </Row>
      <Row>
        <DateDisplay value={from} />
        <DateDisplay value={" - "} />
        <DateDisplay value={to} />
      </Row>
      <Row></Row>
    </div>
  );
};

export default WettkampfTitle;
