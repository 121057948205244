import { PageHeader, message } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import ThemeSwitch from "./ThemeSwitch";
import ImpressumLink from "./ImpressumLink";
import AppInfo from "./AppInfo";

const PageHead = () => {
  const navigate = useNavigate();
  message.config({
    top: 50,
    duration: 0,
    maxCount: 1,
  });
  return (
    <PageHeader
      title=""
      onBack={() => navigate("app.html", { replace: true })}
      extra={[
        <ImpressumLink key="Impressum" />,
        <ThemeSwitch key="DarkMode" />,
        <AppInfo key="AppInfo" />,
      ]}
      backIcon={<HomeOutlined />}
    />
  );
};

export default PageHead;
