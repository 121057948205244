import React from "react";
import { Modal } from "antd";

import {
  DataBoatType,
  DataCleanedBoatType,
  DataCleanedBoatTypeToString,
  DataRaceType,
} from "../helper/myTypes";
import Scattergraph from "./Scattergraph";
import { getTimeValue } from "../Race/Boatlist";
import { cleanedBoatData } from "../Boat/Boat";
import htmlDecode from "../helper/htmlDecode";

type SpeedGraphType = {
  race: DataRaceType;
  races: DataRaceType[];
  setVisible: (arg1: boolean | null) => void;
};
type SpeedGraphStateType = {
  svgRef: React.RefObject<SVGSVGElement>;
};

export type ScatterData = {
  r: string;
  rn: number;
  l: string;
  t: string;
  tJs: number;
  data: DataCleanedBoatType;
};

export default class SpeedGraph extends React.Component<
  SpeedGraphType,
  SpeedGraphStateType
> {
  constructor(props: SpeedGraphType) {
    super(props);
    this.state = { svgRef: React.createRef() };
  }

  generatePlotData = () => {
    const { races, race } = this.props;
    const myRaces = races.filter((r) => r.race_class === race.race_class);
    const myData: ScatterData[] = [];
    myRaces.forEach((r, index) => {
      const { race_run: raceRun, lane_max: laneMax } = r;
      const boats: DataBoatType[] = [];
      for (let i = 1; i <= laneMax; i += 1) {
        const b = r[i];
        if (b) {
          boats.push(b);
        }
      }
      boats.forEach((b) => {
        const t = b[1];
        const l = b.bahn;
        if (t) {
          const finalTime = t[0];
          if (finalTime) {
            const jsTime = getTimeValue(b);
            if (jsTime > 0) {
              myData.push({
                t: finalTime,
                tJs: jsTime,
                r: raceRun,
                rn: index,
                l: l,
                data: cleanedBoatData(b[0]),
              });
            }
          }
        }
      });
    });
    return { myData, races: myRaces };
  };

  generateAllBoats = (data: ScatterData[]): string[] => {
    let myBoats = data.map((d) => DataCleanedBoatTypeToString(d.data));
    myBoats = myBoats.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    return myBoats;
  };

  render = () => {
    const { setVisible, race } = this.props;
    const { myData, races } = this.generatePlotData();
    const allBoats = this.generateAllBoats(myData);
    return (
      <Modal
        width="100vw"
        destroyOnClose={true}
        onCancel={() => {
          setVisible(null);
        }}
        open={true}
        title={htmlDecode(race.race_class)}
        footer={null}
        bodyStyle={{ padding: "10px" }}
      >
        <Scattergraph data={myData} races={races} allBoats={allBoats} />
      </Modal>
    );
  };
}
