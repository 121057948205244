import { Image, Row, Col, Card } from "antd";
import baseurl from "../helper/baseurl";

type WerbeElementType = {
  image: string;
  title: string;
  url: string;
};

const WerbeElement = ({ image, title, url }: WerbeElementType) => {
  return (
    <Row
      justify="space-around"
      style={{
        width: "100%",
        paddingBottom: "10px",
        paddingTop: "10px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <Col span={24}>
        <Card
          bordered={false}
          headStyle={{ padding: "1px" }}
          bodyStyle={{ padding: "10px" }}
          className="CardWerbung"
          cover={
            <Image
              className="Werbebild"
              preview={false}
              src={image}
              alt="imas-sport.com"
            />
          }
        >
          <Card.Meta
            title={
              <a href={url} target="_blank">
                <b>{title}</b>
              </a>
            }
          />
        </Card>
      </Col>
    </Row>
  );
};

const Werbung = () => {
  return (
    <div>
      <WerbeElement
        image={baseurl + "/images/startanlage1.jpg"}
        title="Perfect Start with IMAS Startsystems"
        url="https://imas-sport.com/startsystem"
      />

      <WerbeElement
        image={baseurl + "/images/timesystem1.png"}
        title="Time Measurement System"
        url="https://imas-sport.com/timesystem"
      />

      <WerbeElement
        image={baseurl + "/images/ampel1.png"}
        title="Equipment"
        url="https://imas-sport.com/equipment"
      />
    </div>
  );
};

export default Werbung;
