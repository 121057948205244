import colors from "nice-color-palettes/100.json";

const rowIndex = 0;

interface SportColorTableType {
  [key: string]: string;
}

const SportColorTable: SportColorTableType = {
  KSP: colors[0][rowIndex],
  ROW: colors[1][rowIndex],
  DRAG: colors[2][rowIndex],
  PAR: colors[8][rowIndex],
  RBL: colors[9][rowIndex],
  KMAR: colors[10][rowIndex],
  SUP: colors[17][rowIndex],
};

export default SportColorTable;
