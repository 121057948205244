import { Button, Row } from "antd";
import baseurl from "../helper/baseurl";

const ToResultsPHP = () => {
  const url = baseurl + "/index.php";
  return (
    <Row>
      <Button type="primary" block size="small" href={url}>
        Classic Design
      </Button>
    </Row>
  );
};

export default ToResultsPHP;
