import { Row, Alert } from "antd";
import { memo } from "react";
import htmlDecode from "../helper/htmlDecode";
import { DataRaceType } from "../helper/myTypes";

type RaceInfoTextType = {
  race: DataRaceType;
};

const RaceInfoText = memo(function RaceInfoText({ race }: RaceInfoTextType) {
  const { texte } = race;
  if (texte && texte.length > 0) {
    return (
      <Alert
        type="info"
        style={{ marginTop: "5px" }}
        message={
          <>
            {texte.map((t) => {
              if (!t || t === "") {
                return null;
              }
              return (
                <Row key={t}>{htmlDecode(t.replace(/<\/?\w\d>/g, ""))}</Row>
              );
            })}
          </>
        }
      />
    );
  }
  return null;
});

export default RaceInfoText;
