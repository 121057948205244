import baseurl, { debugParser } from "./baseurl";
import handleErrors from "./fetchHelper";
import { DataRaceType } from "./myTypes";
import offlineWarning, { removeWarning } from "./offlineWarning";

const parseRace = async (wkID: string, wkOrg: string, rID: number) => {
  const url =
    baseurl +
    "/API_IMAS_race.php" +
    "?wettkampfOrg=" +
    wkOrg +
    "&competition=" +
    wkID +
    "&race_id=" +
    rID;

  try {
    const data = await fetch(url).then(handleErrors);
    const content = await data.text();
    const raceInfo: DataRaceType = JSON.parse(content);
    removeWarning();
    return raceInfo;
  } catch (e) {
    if (debugParser) {
      console.warn(e, url);
    }
    offlineWarning();
    return null;
  }
};

export default parseRace;
