import { Alert, Button, Row } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Printd } from "printd";
import baseurl from "../helper/baseurl";
import { DataRaceType } from "../helper/myTypes";
import SpeedGraphWrapper from "../SpeedGraph/SpeedGraphWrapper";
import Boatlist from "./Boatlist";
import OtherRaces from "./OtherRaces";
import RaceInfoText from "./RaceInfoText";
import RaceLinks from "./RaceLinks";
import { useQueryParam } from "use-query-params";
import {
  ParamWkID,
  ParamWkIDType,
  ParamWkOrg,
  ParamWkOrgType,
} from "../helper/queryParamNames";
import { memo } from "react";

type OfficialType = {
  race: DataRaceType;
};

const Official = memo(function Official({ race }: OfficialType) {
  const { status } = race;
  if (status === "SO") {
    return (
      <Alert
        style={{ width: "100%" }}
        type="success"
        message="OFFICIAL Result"
      />
    );
  }
  return (
    <Alert style={{ width: "100%" }} type="error" message="INOFFICIAL Result" />
  );
});

type RaceType = {
  race: DataRaceType;
  races: DataRaceType[];
  flags: boolean;
  distanceSort: number;
};

const Race = memo(function Race({
  race,
  races,
  flags,
  distanceSort,
}: RaceType) {
  const [wkID] = useQueryParam(ParamWkID, ParamWkIDType);
  const [wkOrg] = useQueryParam(ParamWkOrg, ParamWkOrgType);
  return (
    <div>
      <Row wrap={false}>
        <Official race={race} />
        <Button
          size="large"
          icon={<PrinterOutlined />}
          onClick={() => {
            const url =
              baseurl +
              "/" +
              wkOrg +
              "/" +
              "race.php" +
              "?competition=" +
              wkID +
              "&race_id=" +
              race.id;
            const d = new Printd();
            d.printURL(url, ({ launchPrint }) => {
              console.log("launchPrint");
              launchPrint();
            });
          }}
        />
      </Row>
      <RaceLinks race={race} />
      <Boatlist flags={flags} race={race} distanceSort={distanceSort} />
      <RaceInfoText race={race} />
      <OtherRaces race={race} races={races} />
      <SpeedGraphWrapper race={race} races={races} />
    </div>
  );
});

export default Race;
