import Router from "./components/Router";
import "./App.css";

function App() {
  if (window.swUpdateReady) {
    window.swUpdateReady = false;
    window.stop();
    window.location.reload();
  }
  return (
    <>
      <Router />
    </>
  );
}

export default App;
