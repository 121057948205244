import { Button, Row } from "antd";
import React from "react";
import SpeedGraph from "./SpeedGraph";

import { DataRaceType } from "../helper/myTypes";
import { useQueryParam } from "use-query-params";
import { ParamGraph, ParamGraphType } from "../helper/queryParamNames";
type SpeedGraphWrapperType = {
  race: DataRaceType;
  races: DataRaceType[];
};

const raceHasRanks = (race: DataRaceType) => {
  for (let i = 0; i <= race.lane_max; i += 1) {
    const b = race[i];
    if (b && b.rank && b.rank !== "-") {
      return true;
    }
  }
  return false;
};

const SpeedGraphWrapper = ({ race, races }: SpeedGraphWrapperType) => {
  const [visible, setVisible] = useQueryParam(ParamGraph, ParamGraphType);
  const enabled = raceHasRanks(race);
  return (
    <Row className="SpeedGraphDiv" style={{ marginTop: "5px" }}>
      {visible ? (
        <SpeedGraph race={race} races={races} setVisible={setVisible} />
      ) : null}
      <Button
        className="SpeedGraphButton"
        disabled={!enabled}
        onClick={() => {
          setVisible(true);
        }}
        type="primary"
      >
        Speedgraph
      </Button>
    </Row>
  );
};

export default SpeedGraphWrapper;
